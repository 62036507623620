import React, { Fragment, useState, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useChatContext } from "../../contexts/ChatContext";
import { useSession } from "../../contexts/SessionContext";
import DeleteChatPopup from "../../components/Popups/DeleteChatPopup/DeleteChatPopup";
import RenameChatPopup from "../../components/Popups/RenameChatPopup/RenameChatPopup";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import Sidebar from "../../components/Sidebar/Sidebar";
import NavBar from "../../components/NavBar/NavBar";
import ChangeLog from "../../components/ChangeLog/ChangeLog";
import ReportForm from "../../components/ReportForm/ReportForm";
import getUserInfo from "../../server/user-auth/UserInfo";
import MobileMessageOptions from "../../components/ChatWindow/Message/MobileMessageOptions/MobileMessageOptions";
import FeedbackDialog from "../../components/FeedbackDialog/FeedbackDialog";

function MainPage() {
  const {
    mobileNavBarOpen,
    setMobileNavBarOpen,
    deletedChatId,
    renamedChatId,
    renamedChatSummary,
    changelogOpen,
    reportForm,
    showMobileMessagesOptions,
    setShowMobileMessagesOptions,
    isTyping,
    isSearchDialogOpen,
  } = useChatContext();

  const { sessionId, setUsername, setFirebaseUserId } = useSession();
  const [showFeedback, setShowFeedback] = useState(false);
  const [shouldShowFeedback, setShouldShowFeedback] = useState(false);
  const typingTimerRef = useRef(null);

  const handleCloseSidebar = () => {
    setMobileNavBarOpen(false);
  };

  useEffect(() => {
    const userInfo = getUserInfo();
    const username = userInfo.fullName.replace(/\s/g, ".").toLowerCase();
    setUsername(username);
    setFirebaseUserId(userInfo.uid);

    if (window.clarity) {
      window.clarity("identify", username, sessionId);
    }
  }, [setUsername, setFirebaseUserId, sessionId]);

  useEffect(() => {
    setShouldShowFeedback(true);
    if (isTyping) {
      typingTimerRef.current = setTimeout(() => {
        setShowFeedback(true);
      }, 30000);

      return () => {
        clearTimeout(typingTimerRef.current);
      };
    } else {
      clearTimeout(typingTimerRef.current);
      setShouldShowFeedback(false);
      setTimeout(() => {
        setShowFeedback(false);
      }, 300);
    }
  }, [isTyping]);

  return (
    <div className="relative flex sm:h-[100vh] smallMobile:h-dvh antialiased">
      {deletedChatId && <DeleteChatPopup chatUuid={deletedChatId} />}
      {renamedChatId && (
        <RenameChatPopup
          chatUuid={renamedChatId}
          chatSummary={renamedChatSummary}
        />
      )}
      {showFeedback && (
        <FeedbackDialog
          isWarning={true}
          shouldShow={shouldShowFeedback}
          title={"Aguarde um momento"}
          body={
            "O processamento da resposta está demorando um pouco mais que o comum."
          }
          onClose={() => setShowFeedback(false)}
        />
      )}
      {changelogOpen && <ChangeLog />}
      {reportForm && <ReportForm />}
      {showMobileMessagesOptions ? (
        <>
          <MobileMessageOptions
            message={showMobileMessagesOptions}
            onClose={() => setShowMobileMessagesOptions(null)}
          />
        </>
      ) : null}
      <div
        className={`relative flex sm:h-[100vh] w-full smallMobile:h-dvh transition duration-300 ease-in-out ${
          deletedChatId ||
          renamedChatId ||
          changelogOpen ||
          reportForm ||
          isSearchDialogOpen
            ? "blur-sm"
            : "blur-none"
        }`}
      >
        <Transition
          as={Fragment}
          show={mobileNavBarOpen}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="transform-gpu"
        >
          <div
            className={`horizontalTablet:hidden fixed inset-y-0 left-0 w-[80vw] z-50 flex pointer-events-none`}
          >
            {/* Contêiner para o Sidebar */}
            <div className="flex-1 overflow-auto shadow-lg pointer-events-auto z-60">
              <Sidebar />
            </div>

            <XMarkIcon
              className="p-2 text-slate-500 pointer-events-auto size-12 hover:text-slate-700 dark:hover:text-slate-200 focus:outline-none"
              onClick={handleCloseSidebar}
            />
          </div>
        </Transition>
        <div className="sm:flex flex-col text-white smallMobile:hidden w-[300px] md:w-[300px] border-black">
          <Sidebar />
        </div>

        <div
          className={`text-white flex-grow shadow-xl bg-defaultWhite dark:bg-[#0b1120] transition duration-300 ease-in-out ${
            mobileNavBarOpen
              ? "smallMobile:blur-sm"
              : "horizontalTablet:blur-none"
          }`}
        >
          <NavBar onToggle={setMobileNavBarOpen} />
          <div className="h-full" onClick={handleCloseSidebar}>
            <ChatWindow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
