import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";

export default function NavBar({ onToggle }) {
  return (
    <Disclosure as="nav" className="fixed w-full smallMobile:z-40 sm:z-10">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-2 sm:px-6 lg:px-8 smallMobile:bg-defaultWhite/65 dark:smallMobile:bg-[#0b1120]/65 backdrop-blur-lg backdrop-filter sm:hidden dark:sm:hidden">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="relative inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:bg-slate-100 dark:hover:bg-slate-500/50 hover:text-slate-500 dark:hover:text-slate-200"
                  onClick={() => onToggle((open = true))}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only z-60">Abrir menu</span>
                  <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                </Disclosure.Button>
                <div className="flex h-16 shrink-0 items-center text-[#242424] dark:text-slate-200 font-bold text-2xl justify-center ml-3">
                  Tadeo
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="sticky h-[10px] left-0 right-0 bottom-0 z-index-100"
            style={{
              backgroundImage: "linear-gradient(to bottom, white, transparent)",
              pointerEvents: "none",
            }}
          ></div> */}
        </>
      )}
    </Disclosure>
  );
}
