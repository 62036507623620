import { getInitials } from "../../server/common-functions/capitalizeName";
import getUserInfo from "../../server/user-auth/UserInfo";
import React from "react";

/**
 * Componente UserAvatar.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.size - O tamanho do avatar. Pode ser "default", "small", "large", "x-large" ou "xx-large".
 * @param {boolean} [props.chatting=false] - Indica se o usuário está conversando no momento.
 * @param {Object} props.message - O objeto da mensagem.
 * @param {string} props.message.role - O papel do remetente da mensagem, pode ser "assistant" ou outro papel.
 * @param {boolean} props.isFromAssistant - Indica se a mensagem é de um assistente.
 * @returns {JSX.Element|null} O componente avatar renderizado ou null se nenhum tamanho ou estado de conversa corresponder.
 *
 * @example
 * return (
 *   <UserAvatar
 *     size="default"
 *     chatting={true}
 *     message={{ role: "user" }}
 *     isFromAssistant={false}
 *   />
 * );
 */
function UserAvatar({ size, chatting = false, message, isFromAssistant }) {
  const userInfo = getUserInfo();
  if (size === "default") {
    return (
      <span className="inline-flex items-center justify-center bg-slate-500 rounded-lg size-10 min-h-10 min-w-10">
        <span className="font-medium leading-none text-white select-none">
          {getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  if (size === "small") {
    return (
      <span className="inline-flex items-center justify-center bg-slate-500 rounded-lg size-8 min-h-8 min-w-8">
        <span className="text-xs font-medium leading-none text-white select-none">
          {getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  if (size === "large") {
    return (
      <span className="inline-flex items-center justify-center bg-slate-500 rounded-lg size-12 min-h-12 min-w-12">
        <span className="text-sm font-medium leading-none text-white select-none">
          {getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  if (size === "x-large") {
    return (
      <span className="inline-flex items-center justify-center bg-slate-500 rounded-lg size-16 min-h-16 min-w-16">
        <span className="text-lg font-medium leading-none text-white select-none">
          {getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  if (size === "xx-large") {
    return (
      <span className="inline-flex items-center justify-center bg-slate-500 rounded-lg size-24 min-h-24 min-w-24">
        <span className="text-xl font-medium leading-none text-white select-none">
          {getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  if (chatting) {
    return (
      <span className="inline-flex items-center justify-center mr-2 bg-slate-500 rounded-lg size-8 min-h-8 min-w-8">
        <span className="text-xs font-medium leading-none text-white select-none">
          {isFromAssistant
            ? "T"
            : message.role === "assistant"
              ? "T"
              : getInitials(userInfo.fullName)}
        </span>
      </span>
    );
  }
  return null;
}

export default UserAvatar;
