import React, { useEffect } from "react";
import LoginV2 from "../../components/Login/LoginV2";
import { logout } from "../../server/user-auth/Logout";

function AuthPage() {
  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="h-full antialiased">
      {/* <Login /> */}
      <LoginV2 />
    </div>
  );
}

export default AuthPage;
