import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

function MainFrame() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 50);
  }, []);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="transform-gpu"
    >
      <div className="z-10 flex items-center justify-center w-full h-full">
        <div className="absolute top-0 right-0 left-0 h-[50vh]"></div>
        <div className="z-20 flex flex-col items-center justify-center w-full mx-auto space-x-6">
          <div className="flex flex-col pb-2 mb-4 text-center select-none">
            <p className="mb-2 text-2xl font-bold text-slate-700 dark:text-slate-200">
              Olá! Como posso ajudar com seus projetos hoje?
            </p>
            <p className="text-[#374151] dark:text-slate-300 text-sm font-medium">
              Projetado para tirar todas as suas dúvidas sobre os projetos da
              DEO
            </p>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default MainFrame;
