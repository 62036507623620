import React, { useState, useEffect, Fragment, useRef } from "react";
import { Transition } from "@headlessui/react";
import { useChatContext } from "../../../contexts/ChatContext";
import { downloadReport } from "../../../server/downloadReport";
import ReportDownloadButton from "./ReportDownloadButton/ReportDownloadButton";
import LoadMessage from "../LoadMessage/LoadMessage";
import UserAvatar from "../../UserAvatar/UserAvatar";
import MessageOptions from "./MessageOptions/MessageOptions";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";

const Message = ({
  message,
  typing,
  serverError,
  showNotAuthenticatedError,
}) => {
  const {
    resetConversation,
    setOnlyRead,
    setResetConversation,
    setShowMobileMessagesOptions,
  } = useChatContext();

  const [show, setShow] = useState(false);
  const touchTimeoutRef = useRef(null);
  const isTouchActiveRef = useRef(false);

  useEffect(() => {
    if (resetConversation) {
      setResetConversation(false);
    }
  }, [resetConversation, setResetConversation]);

  useEffect(() => {
    if (message && message.answer) {
      const shouldSetReadOnly = message.answer.includes("reports/regenerate");
      setOnlyRead(shouldSetReadOnly);
    }
  }, [message, setOnlyRead]);

  async function handleLinkClick(link) {
    await downloadReport(link);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  const handleTouchStart = () => {
    if (message.role === "assistant" && !isTouchActiveRef.current) {
      if (navigator.vibrate) navigator.vibrate(50);

      isTouchActiveRef.current = true;

      touchTimeoutRef.current = setTimeout(() => {
        setShowMobileMessagesOptions(message);
        isTouchActiveRef.current = false;
      }, 1500);
    }
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimeoutRef.current);
    isTouchActiveRef.current = false;
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative">
        <div
          className="flex justify-start w-full max-w-full m-2 my-4 transition duration-100 ease-in-out smallMobile:select-none sm:select-text"
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <UserAvatar
            chatting={true}
            message={message}
            isFromAssistant={typing || serverError || showNotAuthenticatedError}
          />
          <div
            className={`flex items-center flex-wrap w-full px-2 max-w-[750px] text-[#242424] dark:text-[#e5e7eb]`}
            onClick={() => {
              if (
                message.answer &&
                message.answer.includes("reports/regenerate")
              ) {
                handleLinkClick(message.answer);
              }
            }}
          >
            <div className="relative w-full">
              <div className="absolute top-0 font-bold select-none text-[#242424] dark:text-[#e5e7eb]">
                {typing || serverError || showNotAuthenticatedError
                  ? "Tadeo"
                  : message.role === "assistant"
                    ? "Tadeo"
                    : "Você"}
              </div>
              {message.role === "assistant" ? (
                <MessageOptions message={message} />
              ) : (
                ""
              )}
              <br />
              {/* text message */}
              <div
                className={`max-w-full text-pretty overflow-wrap smallMobile:break-words verticalTablet:break-normal verticalTablet:hyphens-auto ${
                  serverError ? "text-red-500 dark:text-red-400" : ""
                } prose dark:prose-dark`}
                lang="pt-BR"
              >
                {serverError ? (
                  "Desculpe-nos, mas parece que nosso servidor está enfrentando um problema momentâneo. Estamos cientes e trabalhando para corrigir isso. Por favor, tente novamente em breve."
                ) : showNotAuthenticatedError ? (
                  <div className="w-full text-red-500 dark:text-red-400">
                    Sua conta não possui autorização para acessar esta
                    funcionalidade. Solicitamos que por gentileza preencha o{" "}
                    <a
                      href="https://forms.clickup.com/9007081222/f/8cdu9r6-6511/H3MZRM238G3QLSROJP?Nome%20da%20tarefa=Solicitação%20de%20Acesso"
                      className="inline font-bold cursor-pointer hover:underline"
                    >
                      formulário de solicitação de acesso
                    </a>
                    .
                  </div>
                ) : typing ? (
                  <LoadMessage />
                ) : message.role === "assistant" ? (
                  message.answer.includes("reports/regenerate") ? (
                    <ReportDownloadButton />
                  ) : (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm, remarkBreaks]}
                      components={{
                        table: ({ node, ...props }) => (
                          <div className="overflow-x-auto smallMobile:w-[75svw] sm:w-[50svw] horizontalTablet:w-full">
                            <table
                              className=" table-auto max-w-full text-[#242424] dark:text-[#e5e7eb] bg-slate-50/90 dark:bg-slate-900/90 rounded-xl px-5 backdrop-blur-lg backdrop-filter ring-inset my-[0px] ring-1 ring-slate-200/50 dark:ring-slate-600/50"
                              {...props}
                            />
                          </div>
                        ),
                        th: ({ node, ...props }) => (
                          <th
                            className="whitespace-nowrap text-[#242424] dark:text-[#e5e7eb] border-b dark:border-slate-600 border-slate-300 mx-2 px-5 pt-3"
                            {...props}
                          />
                        ),
                        td: ({ node, ...props }) => (
                          <td
                            className="text-[#242424] dark:text-[#e5e7eb] border-b dark:border-slate-600 border-slate-300 mx-2 text-nowrap px-5"
                            {...props}
                          />
                        ),
                        h1: ({ node, ...props }) => (
                          <h1
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        h2: ({ node, ...props }) => (
                          <h2
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        h3: ({ node, ...props }) => (
                          <h3
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),
                        p: ({ node, ...props }) => (
                          <p
                            className="text-[#242424] dark:text-[#e5e7eb]"
                            {...props}
                          />
                        ),

                        // Add more custom renderers as needed
                      }}
                    >
                      {message.answer || message.error_message}
                    </ReactMarkdown>
                  )
                ) : (
                  message.question
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Message;
