import { Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import ListPicker from "../ListPicker/ListPicker";
import departments from "../../../../config/departments.json";
import CardsRadioGroup from "../CardsRadioGroup/CardsRadioGroup";
import SearchField from "../SearchField/SearchField";

function ReportTypes({
  projectName,
  setProjectName,
  responsibleName,
  setResponsibleName,
  taticalProjectName,
  setTaticalProjectName,
  reportType,
  setReportType,
  setAdditionalInfo, // Adicionando prop para setar o additionalInfo
}) {
  const [showReportSection, setShowReportSection] = useState(false);
  const [subType, setSubType] = useState("");

  const handleChangeReportType = (newType) => {
    if (reportType !== newType) {
      setProjectName("");
      setResponsibleName("");
      setTaticalProjectName("");
      setShowReportSection(false);
      setSubType("");
      setTimeout(() => {
        setReportType(newType);
        setShowReportSection(true);
      }, 319);
    }
  };

  const handleSubTypeChange = (newSubType) => {
    setSubType(newSubType);
    // Atualizar additionalInfo com base no subtipo selecionado
    if (newSubType === "specific-project") {
      setAdditionalInfo("Projeto específico selecionado");
    } else if (newSubType === "specific-program") {
      setAdditionalInfo("Programa específico selecionado");
    } else {
      setAdditionalInfo("");
    }
  };

  return (
    <>
      <div className="mt-10 space-y-10">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-200">
            Tipo de relatório
          </legend>
          <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
            Escolha a categoria do relatório que deseja produzir.
          </p>
          <div className="mt-6 space-y-6">
            <div className="flex items-center gap-x-3">
              <input
                id="specific-management"
                name="report-type"
                type="radio"
                value="specific-management"
                checked={reportType === "specific-management"}
                onChange={() => handleChangeReportType("specific-management")}
                className="transition-colors duration-300 radio-button"
              />
              <label htmlFor="specific-management" className="radio-label">
                De uma gerência específica
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="specific-project"
                name="report-type"
                value="specific-project"
                checked={reportType === "specific-project"}
                onChange={() => handleChangeReportType("specific-project")}
                type="radio"
                className="transition-colors duration-300 radio-button"
              />
              <label htmlFor="specific-project" className="radio-label">
                De um projeto ou programa específico
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="specific-responsible"
                name="report-type"
                value="specific-responsible"
                checked={reportType === "specific-responsible"}
                onChange={() => handleChangeReportType("specific-responsible")}
                type="radio"
                className="transition-colors duration-300 radio-button"
              />
              <label htmlFor="specific-responsible" className="radio-label">
                De um responsável específico
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                id="tactical-status"
                name="report-type"
                value="tactical-status"
                checked={reportType === "tactical-status"}
                onChange={() => handleChangeReportType("tactical-status")}
                type="radio"
                className="transition-colors duration-300 radio-button"
              />
              <label htmlFor="tactical-status" className="radio-label">
                Status tático
              </label>
            </div>
          </div>
        </fieldset>
      </div>

      {/* se for de uma gerência específica */}
      <Transition
        show={showReportSection && reportType === "specific-management"}
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-4"
      >
        <div className="mt-10 space-y-10">
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-200">
              De uma gerência específica
            </legend>
            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
              Escolha a gerência que o relatório deve se referir.
            </p>
            <div className="mt-6 space-y-6">
              <ListPicker items={departments} onSelect={setProjectName} />
            </div>
          </fieldset>
        </div>
      </Transition>

      {/* se for de um projeto específico */}
      <Transition
        show={showReportSection && reportType === "specific-project"}
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-4"
      >
        <div className="mt-10 space-y-10">
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-200">
              De um projeto ou programa específico
            </legend>
            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
              Insira o nome do projeto ou programa.
            </p>
            <div className="mt-6 space-y-6">
              <SearchField
                searchIn={"projects"}
                name="project-name"
                id="project-name"
                value={projectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
              />
            </div>
          </fieldset>
        </div>
      </Transition>

      {/* se for de um responsável específico */}
      <Transition
        show={showReportSection && reportType === "specific-responsible"}
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-4"
      >
        <div className="mt-10 space-y-10">
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-200">
              De um responsável específico
            </legend>
            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
              Insira o nome do responsável.
            </p>
            <div className="mt-6 space-y-6">
              <SearchField
                searchIn={"persons"}
                name="responsible-name"
                id="responsible-name"
                value={responsibleName}
                onChange={(e) => {
                  setResponsibleName(e.target.value);
                }}
              />
            </div>
          </fieldset>
        </div>
      </Transition>

      {/* se for status tático */}
      <Transition
        show={showReportSection && reportType === "tactical-status"}
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-y-4"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-4"
      >
        <div className="mt-10 space-y-5">
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-slate-900 dark:text-slate-200">
              Status tático
            </legend>
            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
              Escolha o tipo de relatório e digite seu nome
            </p>
            <div className="mt-6 space-y-6">
              <CardsRadioGroup onSelect={handleSubTypeChange} />
            </div>
          </fieldset>
          <fieldset>
            <SearchField
              searchIn={
                subType === "specific-program" ? "programs" : "projects"
              }
              name="tatical-project-name"
              id="tatical-project-name"
              value={taticalProjectName}
              onChange={(e) => {
                setTaticalProjectName(e.target.value);
              }}
            />
          </fieldset>
        </div>
      </Transition>
    </>
  );
}

export default ReportTypes;
