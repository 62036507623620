import { Fragment, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Transition } from "@headlessui/react";
import {
  XCircleIcon,
  CheckCircleIcon,
  PaperClipIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { downloadReport } from "../../server/downloadReport";

export default function FeedbackDialog({
  isError,
  isWarning,
  title,
  body,
  onClose,
  downloadLink,
  shouldShow,
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (shouldShow) {
      setTimeout(() => {
        setShow(true);
      }, 10);

      if (isWarning) {
        const timer = setTimeout(() => {
          setShow(false);
        }, 15000);

        return () => clearTimeout(timer);
      }
    } else {
      setShow(false);
    }
  }, [isWarning, shouldShow]);

  useEffect(() => {
    if (!show) {
      const timer = setTimeout(() => {
        onClose();
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-40 flex items-end px-4 py-6 pointer-events-none select-none sm:items-start sm:p-6 z-60"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-defaultWhite dark:bg-[#1f2937] shadow-lg ring-1 ring-black dark:ring-slate-600 dark:sm:ring-[#1f2937] ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {isError ? (
                      <XCircleIcon
                        className="w-6 h-6 text-red-400 dark:text-red-500"
                        aria-hidden="true"
                      />
                    ) : isWarning ? (
                      <ClockIcon
                        className="w-6 h-6 text-yellow-700 dark:text-yellow-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="w-6 h-6 text-green-400 dark:text-green-500"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-slate-900 dark:text-slate-200">
                      {title}
                    </p>
                    <p className="mt-1 text-sm text-slate-500 dark:text-slate-300">
                      {body}
                    </p>
                  </div>
                  {!isWarning && (
                    <div className="flex flex-shrink-0 ml-4">
                      <button
                        type="button"
                        className="inline-flex cursor-pointer rounded-md bg-defaultWhite dark:bg-[#1f2937] text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 z-50"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                </div>
                {downloadLink && (
                  <motion.div
                    className="mt-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="border border-slate-200 divide-y divide-slate-100 rounded-md dark:border-slate-600">
                      <div className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                        <div className="flex items-center flex-1 w-0">
                          <PaperClipIcon
                            className="flex-shrink-0 w-5 h-5 text-slate-600 dark:text-slate-400"
                            aria-hidden="true"
                          />
                          <div className="flex flex-1 min-w-0 gap-2 ml-4 text-slate-600 dark:text-slate-200">
                            <span className="font-medium truncate">
                              Project Assistant Report.docx
                            </span>
                          </div>
                        </div>
                        <div className="flex-shrink-0 ml-4">
                          <div
                            className="font-medium text-indigo-400 cursor-pointer hover:text-indigo-300"
                            onClick={() => {
                              downloadReport(downloadLink);
                            }}
                          >
                            Baixar
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
