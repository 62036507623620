import React from "react";
import "./style.css";

const LoadMessage = () => {
  return (
    <>
      <div className="wrapper mt-[10px]">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
      </div>
    </>
  );
};

export default LoadMessage;
