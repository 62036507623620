import React, { useState, useCallback, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Slideover from "../Slideover/Slideover";
import { CheckIcon } from "@heroicons/react/20/solid";
import timeline from "../../config/changelog.json";
import debounce from "lodash/debounce";
import { motion } from "framer-motion";

/**
 * Retorna o tipo de mudança com base no índice e no histórico de mudanças.
 *
 * @param {number} index - O índice atual.
 * @param {Array} timeline - O histórico de mudanças.
 * @returns {string} - O tipo de mudança ("Nova versão", "Funcionalidade" ou "Correção").
 */
function getChangeType(index, timeline) {
  if (index === timeline.length - 1) {
    return "Nova versão";
  }

  const currentVersionParts = timeline[index].version.split(".").map(Number);
  const nextVersionParts = timeline[index + 1].version.split(".").map(Number);

  if (currentVersionParts[0] > nextVersionParts[0]) {
    return "Nova versão";
  } else if (currentVersionParts[1] > nextVersionParts[1]) {
    return "Funcionalidade";
  } else if (currentVersionParts[2] > nextVersionParts[2]) {
    return "Correção";
  }

  return "Correção";
}

/**
 * Retorna a cor associada ao tipo de mudança.
 *
 * @param {number} index - O índice atual.
 * @param {Array} timeline - O histórico de mudanças.
 * @returns {string} - A classe de cor CSS para o tipo de mudança.
 */
function getColorForChangeType(index, timeline) {
  const changeType = getChangeType(index, timeline);
  switch (changeType) {
    case "Nova versão":
      return "fill-indigo-500";
    case "Funcionalidade":
      return "fill-emerald-500";
    case "Correção":
      return "fill-sky-500";
    default:
      return "fill-slate-500";
  }
}

/**
 * Compara duas versões de software.
 *
 * @param {Object} a - O primeiro objeto de versão.
 * @param {Object} b - O segundo objeto de versão.
 * @returns {number} - Retorna -1, 1 ou 0 com base na comparação.
 */
function compareVersions(a, b) {
  const versionA = a.version.split(".").map(Number);
  const versionB = b.version.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (versionA[i] > versionB[i]) return -1;
    if (versionA[i] < versionB[i]) return 1;
  }
  return 0;
}

/**
 * Componente ChangeLog.
 *
 * @component
 * @returns {JSX.Element} O componente que exibe o histórico de mudanças.
 */
const ChangeLog = React.memo(() => {
  const [sortedTimeline, setSortedTimeline] = useState([]);

  const debouncedSetSortedTimeline = useCallback(
    debounce((newTimeline) => {
      setSortedTimeline((prevTimeline) => {
        if (JSON.stringify(prevTimeline) !== JSON.stringify(newTimeline)) {
          return newTimeline;
        }
        return prevTimeline;
      });
    }, 300),
    []
  );

  useEffect(() => {
    const sorted = [...timeline].sort(compareVersions);
    debouncedSetSortedTimeline(sorted);
  }, [debouncedSetSortedTimeline]);

  return (
    <Slideover title={"Histórico de Mudanças"}>
      <div className="flow-root transform-gpu">
        <ul className="-mb-8">
          {sortedTimeline.map((change, changeIdx) => (
            <motion.li
              key={change.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3, delay: changeIdx * 0.1 }}
              className="transform-gpu"
            >
              <div className="relative pb-8">
                {changeIdx !== sortedTimeline.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-slate-200 dark:bg-slate-700"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={
                        "bg-[#111826] h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-900 dark:bg-slate-700"
                      }
                    >
                      <CheckIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 max-w-[85%] flex-1 justify-between space-x-4 pt-1.5">
                    <div className=" w-[100%]">
                      <p className="mb-1 font-bold text-slate-800 dark:text-slate-100">
                        Versão {change.version}
                        <span className="inline-flex items-center gap-x-1.5 cursor-default rounded-md ml-4 -mt-1 align-middle px-2 py-1 text-xs font-medium text-slate-900 dark:text-slate-200 ring-1 ring-inset ring-slate-100/30 select-none">
                          <svg
                            className={`h-1.5 w-1.5 ${getColorForChangeType(
                              changeIdx,
                              sortedTimeline
                            )}`}
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {getChangeType(changeIdx, sortedTimeline)}
                        </span>
                      </p>
                      <div className="prose-sm prose text-slate-800 dark:prose-dark dark:text-slate-100">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {change.description}
                        </ReactMarkdown>
                      </div>
                      <p className="mt-1 text-xs font-bold text-slate-400">
                        Lançada em {change.date}
                      </p>
                    </div>
                    <div className="text-sm text-right text-slate-500 whitespace-nowrap"></div>
                  </div>
                </div>
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
    </Slideover>
  );
});

export default ChangeLog;
