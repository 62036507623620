import React, { createContext, useContext, useState } from "react";

const SessionContext = createContext(null);

export const SessionProvider = ({ children, sessionId }) => {
  const [username, setUsername] = useState("");
  const [firebaseUserId, setFirebaseUserId] = useState("");

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        username,
        setUsername,
        firebaseUserId,
        setFirebaseUserId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
