import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ListPicker({ items, onSelect }) {
  const [selected, setSelected] = useState(items[1]);

  const handleSelectionChange = (selectedItem) => {
    setSelected(selectedItem);
    if (onSelect) {
      onSelect(selectedItem.acronym);
    }
  };

  return (
    <Listbox value={selected} onChange={handleSelectionChange}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="cursor-pointer relative w-full rounded-md bg-defaultWhite dark:bg-slate-900 py-1.5 pl-3 pr-10 text-left text-slate-900 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{selected.name}</span>
                {selected.acronym !== "" && selected.acronym && (
                  <span className="ml-2 text-slate-500 truncate">
                    {"– "}
                    {selected.acronym}
                  </span>
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-slate-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-defaultWhite dark:bg-[#1d2432] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map((department) => (
                  <Listbox.Option
                    key={department.acronym}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-indigo-600 text-white"
                          : "text-slate-900 dark:text-slate-200",
                        "relative cursor-pointer select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={department}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate"
                            )}
                          >
                            {department.name}
                          </span>
                          {department.acronym !== "" && department.acronym && (
                            <span
                              className={classNames(
                                active ? "text-indigo-200" : "text-slate-500",
                                "ml-2 truncate"
                              )}
                            >
                              {"– "}
                              {department.acronym}
                            </span>
                          )}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
