export default function TextArea({
  comment,
  setComment,
  commentMaxLength,
  commentMinLength,
}) {
  const handleCommentChange = (event) => {
    const newText = event.target.value;
    // Limita o texto a 1000 caracteres antes de atualizar o estado
    if (newText.length <= commentMaxLength) {
      setComment(newText);
    }
  };

  return (
    <div className="grid grid-cols-1 mt-10 mb-10 gap-x-6 gap-y-8 sm:grid-cols-6">
      <div className="col-span-full">
        <label
          htmlFor="about"
          className="block text-sm font-medium leading-6 select-none text-slate-900 dark:text-slate-100"
        >
          Descreva seu comentário{" "}
          <span className="text-red-600 dark:text-red-400">*</span>
        </label>
        <div className="mt-2">
          <textarea
            id="about"
            name="about"
            rows={3}
            className="block w-full dark:bg-slate-900 rounded-md border-0 py-1.5 text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-700 placeholder:text-slate-400 transition-all duration-300 ease-in-out focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={comment}
            onChange={handleCommentChange} // Evento para atualizar o estado do comentário
          />
        </div>
        <div className="relative flex mt-3 text-sm leading-6">
          <p className="select-none text-slate-600 dark:text-slate-400">
            Escreva de forma clara e detalhada seu comentário.
          </p>
          <p className="absolute right-0 select-none text-slate-600 dark:text-slate-400">
            <span
              className={`${
                comment.length < commentMinLength && comment.length !== 0
                  ? "text-red-600 dark:text-red-400"
                  : ""
              }`}
            >
              {comment.length}
            </span>
            /{commentMaxLength}
          </p>
        </div>
      </div>
    </div>
  );
}
