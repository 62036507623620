import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useChatContext } from "../../../contexts/ChatContext";
import { deleteChat } from "../../../server/deleteChat";
import { getHistoric } from "../../../server/getHistoric";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function DeleteChatPopup({ chatUuid }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  const {
    setChatData,
    setMessages,
    currentChatProtocol,
    setCurrentChatProtocol,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setShowReportScreen,
    setCurrentChatType,
    setDeletedChatId,
    resetPagination,
  } = useChatContext();

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDeletedChatId(undefined);
    }, 200); // Correspondente à duração da animação de saída
  };
  const handleDelete = () => {
    deleteChat(chatUuid)
      .then(() => {
        getHistoric()
          .then((response) => {
            setChatData(response);
            resetPagination();
          })
          .catch((error) => {
            console.error("Error fetching history after deleting chat:", error);
          });
      })
      .catch((error) => {
        console.error("Error deleting chat:", error);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="transform-gpu"
        >
          <div className="fixed inset-0 transition-opacity bg-slate-500 bg-opacity-5" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto transform-gpu">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              className="transform-gpu"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-defaultWhite dark:bg-[#1d2432] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-defaultWhite dark:bg-[#1d2432] text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Fechar</span>
                    <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="w-6 h-6 text-red-600 dark:text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-slate-900 dark:text-slate-100"
                    >
                      Deletar conversa
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-slate-500 dark:text-slate-300">
                        Tem certeza de que deseja excluir esta conversa? Todos
                        as mensagens serão removidas permanentemente. Essa ação
                        é irreversível.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      handleDelete();
                      handleClose();
                      if (chatUuid === currentChatProtocol) {
                        setShowReportScreen(false);
                        setMessages([]);
                        setCurrentChatProtocol({});
                        setCurrentChatHistoric([]);
                        setCurrentChatKnowledge({});
                        setCurrentChatType("");
                      }
                    }}
                  >
                    Excluir
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-defaultWhite dark:bg-[#1d2432] px-3 py-2 text-sm font-semibold text-slate-900 dark:text-slate-100 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 hover:bg-slate-50 dark:hover:bg-slate-700 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
