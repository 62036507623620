import { useState, useEffect, Fragment } from "react";
import { Transition } from "@headlessui/react";

export const PromptModels = ({
  onSelect,
  onChatMessages,
  duringConversationSuggestion,
}) => {
  const [firstPrompt, setFirstPrompt] = useState({});
  const [secondPrompt, setSecondPrompt] = useState({});
  const [thirdPrompt, setThirdPrompt] = useState({});

  useEffect(() => {
    const prompts = [
      {
        value:
          "O que você pode me dizer a respeito do projeto Infovia Alagoas?",
      },
      {
        value:
          "Quantas atividades do projeto NE Conectado – Barreiras - BA estão em andamento?",
      },
      {
        value:
          "Quais tarefas do projeto Infovia 01 de Oriximiná - PA tem um percentual de conclusão entre 20 e 80%?",
      },
      { value: "Quais projetos são da Coordenação de Backbone?" },
      { value: "Quantos projetos tem previsão de término pra 2024?" },
      { value: "Quantos projetos estão em fase de execução?" },
      { value: "Quais as próximas atividades da Infovia 01 – Rede Parintins?" },
      { value: "Quais projetos de 2024 são patrocinados pelo FNDCT e PAC?" },
    ];
    const shuffled = prompts.sort(() => 0.5 - Math.random());
    setFirstPrompt({
      ...formatPrompt(shuffled[0].value),
      fullText: shuffled[0].value,
    });
    setSecondPrompt({
      ...formatPrompt(shuffled[1].value),
      fullText: shuffled[1].value,
    });
    setThirdPrompt({
      ...formatPrompt(shuffled[2].value),
      fullText: shuffled[2].value,
    });
  }, []);

  const formatPrompt = (prompt) => {
    const maxChars = 80;
    const maxTitleChars = 25;
    let words = prompt.split(" ");
    let title = "";
    let detail = "";
    let currentLength = 0;

    for (let word of words) {
      if (currentLength + word.length + 1 <= maxTitleChars) {
        title += word + " ";
        currentLength += word.length + 1;
      } else {
        break;
      }
    }

    title = title.trim();
    detail = prompt.slice(title.length).trim();

    if (title.length + detail.length > maxChars) {
      let detailWords = detail.split(" ");
      detail = "";
      let detailLength = 0;
      for (let word of detailWords) {
        if (detailLength + word.length + 1 + title.length <= maxChars) {
          detail += word + " ";
          detailLength += word.length + 1;
        } else {
          break;
        }
      }
      detail = detail.trim();
      if (title.length + detail.length < prompt.length) {
        detail += "...";
      }
    }

    return { title, detail };
  };
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 50);
  }, []);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-500"
      enterFrom="transform translate-y-10 opacity-0"
      enterTo="transform translate-y-0 opacity-100"
      leave="transition ease-in duration-500"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform translate-y-10 opacity-0"
    >
      <div
        className={`smallMobile:w-full smallMobile:h-[95px] horizontalTablet:w-[800px] horizontalTablet:h-[80px] mb-[10px] flex justify-between space-x-2 ${onChatMessages ? "sticky bottom-[-20px]" : ""}`}
      >
        <div
          className="smallMobile:flex-1 flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
          onClick={() => onSelect(firstPrompt.fullText)}
        >
          <span>{firstPrompt.title}</span>
          <span className="text-xs font-medium">{firstPrompt.detail}</span>
        </div>
        <div
          className="smallMobile:flex-1 flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
          onClick={() => onSelect(secondPrompt.fullText)}
        >
          <span>{secondPrompt.title}</span>
          <span className="text-xs font-medium">{secondPrompt.detail}</span>
        </div>
        <div
          className="hidden horizontalTablet:flex flex-col horizontalTablet:flex-1 bg-defaultWhite dark:bg-[#0b1120] border-[1px] border-slate-200 dark:border-slate-600 rounded text-[#374151] dark:text-slate-200 font-bold p-2 text-sm cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 transition-colors duration-300"
          onClick={() => onSelect(thirdPrompt.fullText)}
        >
          <span>{thirdPrompt.title}</span>
          <span className="text-xs font-medium">{thirdPrompt.detail}</span>
        </div>
      </div>
    </Transition>
  );
};
