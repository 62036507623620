import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import InitialMessage from "../config/chatMessages.json";
import getProjectNames from "../server/getProjectNames";
import getResponsibles from "../server/getResponsibles";
import getProgramNames from "../server/getProgramNames";

const ChatContext = createContext();

export const useChatContext = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
  const [chatData, setChatData] = useState({ history: [] });
  const [messages, setMessages] = useState([]);
  const [currentChatHistoric, setCurrentChatHistoric] = useState([]);
  const [currentChatKnowledge, setCurrentChatKnowledge] = useState({});
  const [currentChatProtocol, setCurrentChatProtocol] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showReportScreen, setShowReportScreen] = useState(false);
  const [reportConversation, setReportConversation] = useState(InitialMessage);
  const [isPoll, setIsPoll] = useState();
  const [resetConversation, setResetConversation] = useState(false);
  const [onlyRead, setOnlyRead] = useState(false);
  const [notAuthenticated, setNotAuthenticated] = useState(false);
  const [deletedChatId, setDeletedChatId] = useState();
  const [renamedChatId, setRenamedChatId] = useState();
  const [renamedChatSummary, setRenamedChatSummary] = useState();
  const [mobileNavBarOpen, setMobileNavBarOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("Nova conversa");
  const [showReportError, setShowReportError] = useState(false);
  const [currentChatType, setCurrentChatType] = useState("");
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [reportForm, setReportForm] = useState(false);
  const [showMobileMessagesOptions, setShowMobileMessagesOptions] =
    useState(false);
  const [mobileOptionsCount, setMobileOptionsCount] = useState(0);
  const [currentHistoryPagination, setCurrentHistoryPagination] = useState(1);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [selectedTacticalReportOption, setSelectedTacticalReportOption] =
    useState("specific project");

  const [projectNames, setProjectNames] = useState([]);
  const [programNames, setProgramNames] = useState([]);
  const [responsibles, setResponsibles] = useState([]);

  const resetPagination = () => {
    setCurrentHistoryPagination(1);
  };

  useEffect(() => {
    async function initializeData() {
      try {
        const projects = await getProjectNames();
        setProjectNames(projects);

        const programs = await getProgramNames();
        setProgramNames(programs);

        const responsiblesList = await getResponsibles();
        setResponsibles(responsiblesList);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    }

    initializeData();
  }, []);

  const contextValue = useMemo(
    () => ({
      chatData,
      setChatData,
      messages,
      setMessages,
      currentChatHistoric,
      setCurrentChatHistoric,
      currentChatKnowledge,
      setCurrentChatKnowledge,
      currentChatProtocol,
      setCurrentChatProtocol,
      isTyping,
      setIsTyping,
      showServerError,
      setShowServerError,
      showReportScreen,
      setShowReportScreen,
      reportConversation,
      setReportConversation,
      isPoll,
      setIsPoll,
      resetConversation,
      setResetConversation,
      onlyRead,
      setOnlyRead,
      notAuthenticated,
      setNotAuthenticated,
      deletedChatId,
      setDeletedChatId,
      mobileNavBarOpen,
      setMobileNavBarOpen,
      currentSelection,
      setCurrentSelection,
      renamedChatId,
      setRenamedChatId,
      renamedChatSummary,
      setRenamedChatSummary,
      showReportError,
      setShowReportError,
      currentChatType,
      setCurrentChatType,
      changelogOpen,
      setChangelogOpen,
      reportForm,
      setReportForm,
      showMobileMessagesOptions,
      setShowMobileMessagesOptions,
      mobileOptionsCount,
      setMobileOptionsCount,
      currentHistoryPagination,
      setCurrentHistoryPagination,
      resetPagination,
      isSearchDialogOpen,
      setIsSearchDialogOpen,
      selectedTacticalReportOption,
      setSelectedTacticalReportOption,
      projectNames,
      programNames,
      responsibles,
    }),
    [
      chatData,
      messages,
      currentChatHistoric,
      currentChatKnowledge,
      currentChatProtocol,
      isTyping,
      showServerError,
      showReportScreen,
      reportConversation,
      isPoll,
      resetConversation,
      onlyRead,
      notAuthenticated,
      deletedChatId,
      mobileNavBarOpen,
      currentSelection,
      renamedChatId,
      renamedChatSummary,
      showReportError,
      currentChatType,
      changelogOpen,
      reportForm,
      showMobileMessagesOptions,
      mobileOptionsCount,
      currentHistoryPagination,
      isSearchDialogOpen,
      selectedTacticalReportOption,
      projectNames,
      programNames,
      responsibles,
    ]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};
