import * as chatListUtils from "./utils/chatListUtils";
import React, { useEffect } from "react";
import { getHistoric } from "../../server/getHistoric";
import { useChatContext } from "../../contexts/ChatContext";
import ChatHistory from "./ChatHistory/ChatHistory";
import ChatMenu from "./ChatMenu/ChatMenu";
import SidebarOptions from "./SidebarOptions/SidebarOptions";

export default function Sidebar() {
  const { chatData, setChatData, resetPagination } = useChatContext();

  useEffect(() => {
    getHistoric()
      .then((data) => {
        setChatData(data);
        resetPagination();
      })
      .catch((error) => {});
  }, [setChatData]);

  const sortedGroupedChats = chatListUtils.sortAndGroupChats(chatData.history);

  return (
    <div className="z-10 flex flex-col h-full overflow-hidden bg-gray-900 gap-y-5">
      <div className="flex items-center justify-center h-16 pt-3 text-2xl font-bold select-none shrink-0 text-amber-50">
        Tadeo
      </div>
      <div className="mx-6">
        <ChatMenu />
      </div>
      <div className="flex flex-col flex-1 mx-6 overflow-hidden">
        <div className="flex-1 mt-3 overflow-hidden">
          <ChatHistory history={sortedGroupedChats} />
        </div>
      </div>
      <div className="mx-6">
        <SidebarOptions />
      </div>
    </div>
  );
}
