import { useState } from "react";
import { motion } from "framer-motion";
import {
  HandThumbUpIcon as HandThumbUpIconOutline,
  HandThumbDownIcon as HandThumbDownIconOutline,
  ClipboardIcon as ClipboardIconOutline,
} from "@heroicons/react/24/outline";
import {
  HandThumbUpIcon as HandThumbUpIconSolid,
  HandThumbDownIcon as HandThumbDownIconSolid,
  ClipboardIcon as ClipboardIconSolid,
} from "@heroicons/react/24/solid";
import { copyToClipboard } from "../../../../server/common-functions/copyToClipboard";
import { sendMessageFeedback } from "../../../../server/sendMessageFeedback";
import SuccessDialog from "./SuccessDialog/SuccessDialog";
export default function MessageOptions({ message }) {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleFeedback = async (feedback) => {
    setShowSuccess(true);
    await sendMessageFeedback(message.id, feedback);
  };

  return (
    <div className="absolute top-0 right-0 hidden p-1 space-x-1 rounded-md bg-slate-50/90 dark:bg-slate-900/90 backdrop-blur-lg backdrop-filter ring-inset sm:flex ring-1 ring-slate-200/50 dark:ring-slate-600/50">
      {showSuccess && <SuccessDialog onClose={() => setShowSuccess(false)} />}
      <div
        onMouseEnter={() => handleMouseEnter("thumbsUp")}
        onMouseLeave={handleMouseLeave}
        className="cursor-pointer"
        onClick={() => handleFeedback(1)}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: hoveredIcon === "thumbsUp" ? 0 : 1 }}
          transition={{ duration: 0.1 }}
          className="absolute"
        >
          <HandThumbUpIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: hoveredIcon === "thumbsUp" ? 1 : 0 }}
          transition={{ duration: 0.1 }}
        >
          <HandThumbUpIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
      </div>
      <div
        onMouseEnter={() => handleMouseEnter("thumbsDown")}
        onMouseLeave={handleMouseLeave}
        className="cursor-pointer"
        onClick={() => handleFeedback(0)}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: hoveredIcon === "thumbsDown" ? 0 : 1 }}
          transition={{ duration: 0.1 }}
          className="absolute"
        >
          <HandThumbDownIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: hoveredIcon === "thumbsDown" ? 1 : 0 }}
          transition={{ duration: 0.1 }}
        >
          <HandThumbDownIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
      </div>
      <div
        onMouseEnter={() => handleMouseEnter("clipboard")}
        onMouseLeave={handleMouseLeave}
        className="cursor-pointer"
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: hoveredIcon === "clipboard" ? 0 : 1 }}
          transition={{ duration: 0.1 }}
          className="absolute"
          onClick={() => {
            copyToClipboard(message.answer);
          }}
        >
          <ClipboardIconOutline className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: hoveredIcon === "clipboard" ? 1 : 0 }}
          transition={{ duration: 0.1 }}
          onClick={() => {
            copyToClipboard(message.answer);
          }}
        >
          <ClipboardIconSolid className="text-slate-600 dark:text-slate-300 size-[15px]" />
        </motion.div>
      </div>
    </div>
  );
}
